
body {
  background-color: #101026;
  color: #fff;
}

.clover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
}

a:link, a:active, a:visited {
  text-decoration: none;
  color: #fff;
}

a:focus, a:hover {
  text-decoration: none;
  color: #fff;
}

a.colour:link, a.colour:active, a.colour:visited {
  text-decoration: none;
  color: #00aff7;
}

@supports (mix-blend-mode: lighten) {
  a.colour:link, a.colour:active, a.colour:visited {
    display: inline-block;
    position: relative;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
  }
  a.colour:link::before, a.colour:active::before, a.colour:visited::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #cf2424, #1156be);
    pointer-events: none;
  }
  a.colour:link::before, a.colour:active::before, a.colour:visited::before {
    mix-blend-mode: screen;
  }
}

a.colour:focus, a.colour:hover {
  text-decoration: none;
  color: #101026;
}

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#side-wrapper {
  z-index: 99999;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #fff;
  transition: all 0.5s ease;
}

#wrapper.toggled #side-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 0;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

#menu-toggle {
  position: relative;
  bottom: -50%;
}

@supports (mix-blend-mode: lighten) {
  #menu-toggle {
    display: inline-block;
    position: relative;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
  }
  #menu-toggle::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #cf2424, #cf0059);
    pointer-events: none;
  }
  #menu-toggle::before {
    mix-blend-mode: screen;
  }
}

@media (min-width: 34em) {
  #menu-toggle {
    position: absolute;
    bottom: 4.5em;
    right: 2em;
  }
}

@media (min-width: 48em) {
  #menu-toggle {
    position: absolute;
    bottom: 4.5em;
    right: 2em;
  }
}

@media (min-width: 62em) {
  #menu-toggle {
    position: absolute;
    bottom: 4.5em;
    right: 2em;
  }
}

@media (min-width: 75em) {
  #menu-toggle {
    position: absolute;
    bottom: 4.5em;
    right: 2em;
  }
}

#menu-toggle a {
  text-decoration: none;
}

#menu-toggle a:link, #menu-toggle a:visited, #menu-toggle a:active, #menu-toggle a:hover {
  color: #00aff7;
}

/* Sidebar Styles */
.side-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-nav li {
  text-indent: 0;
  line-height: 40px;
}

.side-nav li a {
  display: block;
  text-decoration: none;
  color: #343a40;
}

.side-nav li a:hover {
  text-decoration: none;
  color: #00aff7;
  background: transparent;
}

.side-nav li a:active, .side-nav li a:focus {
  text-decoration: none;
}

.side-nav > .side-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
  font-family: 'munki-boy';
  font-size: 1.2rem;
  font-weight: 500;
}

.side-nav > .side-brand a {
  color: #343a40;
}

.side-nav > .side-brand a:hover {
  color: #00aff7;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: 250px;
  }
  #side-wrapper {
    width: 0;
  }
  #wrapper.toggled #side-wrapper {
    width: 250px;
  }
  #page-content-wrapper {
    padding: 0;
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.top-bar {
  width: 100%;
  height: 60vw;
  background-color: #00aff7;
  background: url(../../images/backdrop.jpg);
  background-size: auto 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: relative;
}

@media (min-width: 34em) {
  .top-bar {
    width: 100%;
    height: 29.58333333vw;
    background-size: contain;
  }
}

@media (min-width: 48em) {
  .top-bar {
    background: url(../../images/backdrop.jpg) no-repeat center top fixed;
    width: 100%;
    height: 29.58333333vw;
    background-size: contain;
  }
}

@media (min-width: 62em) {
  .top-bar {
    background: url(../../images/backdrop.jpg) no-repeat center top fixed;
    width: 100%;
    height: 29.58333333vw;
    background-size: contain;
  }
}

@media (min-width: 75em) {
  .top-bar {
    background: url(../../images/backdrop.jpg) no-repeat center top fixed;
    width: 100%;
    height: 29.58333333vw;
    background-size: contain;
  }
}

.top-bar .logo {
  font-family: 'Fredoka One', cursive;
  text-transform: uppercase;
  position: relative;
  color: #fff;
  bottom: -35%;
  margin-left: 1em;
  margin-right: 1em;
}

@media (min-width: 34em) {
  .top-bar .logo {
    position: relative;
  }
}

@media (min-width: 48em) {
  .top-bar .logo {
    position: absolute;
    bottom: 60px;
    left: 60px;
  }
}

@media (min-width: 62em) {
  .top-bar .logo {
    position: absolute;
    font-size: 3rem;
    bottom: 60px;
    left: 60px;
  }
}

@media (min-width: 75em) {
  .top-bar .logo {
    position: absolute;
    font-size: 4rem;
    bottom: 60px;
    left: 60px;
  }
}

.top-bar .logo a {
  color: #fff;
}

.top-bar .logo a:link, .top-bar .logo a:active, .top-bar .logo a:visited, .top-bar .logo a:focus {
  color: #fff;
}

.top-bar .logo a:hover {
  color: #00aff7;
}

.content {
  color: #fff;
}

.content img.float-right {
  vertical-align: baseline;
}

.content h2 {
  margin-top: 30px;
  margin-bottom: 30px;
}

header.left-hander {
  background-image: linear-gradient(to right, #00aff7, #101026);
  padding-left: 20px;
  padding-bottom: 2px;
  padding-top: 1px;
  border-bottom-left-radius: 0.5em;
  margin-top: 60px;
  margin-bottom: 60px;
}

header.left-hander .header-inner {
  background-color: #101026;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: 0.4em;
}

iframe.map, iframe.youtube {
  border: none;
  height: 500px;
  width: 100%;
  margin-bottom: 30px;
}

a.youtube-thumb,
a.youtube-link {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container.home {
  margin-top: 30px;
  margin-bottom: 30px;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-columns {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}

@media (min-width: 34em) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 48em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 62em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 75em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

.btn-primary {
  background-color: #00aff7;
  background-image: linear-gradient(to right, #00aff7, #101026);
  border: none;
  color: #fff;
}

.btn-primary:hover, .btn-primary:active, .btn-primaryfocus {
  background-color: #0079ab;
  color: #fff;
}

.btn-primary:link, .btn-primary:visited {
  box-shadow: 2px #00aff7;
  color: #fff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0079ab;
  border: none;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem #0079ab;
  color: #fff;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #00aff7;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0079ab;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00aff7;
  color: #fff;
}

footer, .bottom-footer {
  background-color: rgba(0, 0, 0, 0.4);
}

footer {
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
}